<template>
  <page-container title="日志管理" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="monitorpointnum" style="width: 25%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="角色" prop="rolename" style="width: 25%">
              <a-select v-model="queryParams.rolename">
                <a-select-option value="">全部</a-select-option>
                <a-select-option value="系统维护管理员">系统维护管理员</a-select-option>
                <a-select-option value="督导管理员">督导管理员</a-select-option>
                <a-select-option value="督导管理员（工程管理）">督导管理员（工程管理）</a-select-option>
                <a-select-option value="督导人员">督导人员</a-select-option>
                <a-select-option value="项目管理员">项目管理员</a-select-option>
                <a-select-option value="项目工程管理员">项目工程管理员</a-select-option>
                <a-select-option value="项目其他管理员">项目其他管理员</a-select-option>
                <a-select-option value="项目调度员">项目调度员</a-select-option>
                <a-select-option value="项目工程值班员">项目工程值班员</a-select-option>
                <a-select-option value="维保组长">维保组长</a-select-option>
                <a-select-option value="维保组员">维保组员</a-select-option>
                <a-select-option value="自保组长">自保组长</a-select-option>
                <a-select-option value="自保组员">自保组员</a-select-option>
                <a-select-option value="管家">管家</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="时间范围" prop="logdate" style="width: 25%;">
              <a-range-picker v-model="queryParams.logdate" :default-value="[moment(`${starttime.substring(0,10)}`, 'YYYY-MM-DD'), moment(`${endtime.substring(0,10)}`, 'YYYY-MM-DD')]"></a-range-picker>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 20%;">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="账号" prop="useraccount" style="width: 25%;">
              <a-input v-model.trim="queryParams.useraccount" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="姓名" prop="username" style="width: 25%;">
              <a-input v-model.trim="queryParams.username" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="日志类型" prop="logtype" style="width: 25%;">
              <a-select v-model="queryParams.logtype">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option v-for="(item, index) in logTypes" :key="index" :value="index">{{item}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="日志内容" prop="logcontent" style="width: 25%;">
              <a-input v-model.trim="queryParams.logcontent" placeholder="请输入"></a-input>
            </a-form-model-item>

          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">日志列表</h3>
          <div class="table-btns">
            <!--            <a-button type="primary" @click="showModal()"><a-icon type="plus"></a-icon>新建</a-button>-->
            <a-button @click="exportModal" style="margin-right: 10px"><a-icon type="export"></a-icon>批量导出</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="logid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="logtype" slot-scope="value">
            {{logTypes[value]}}
          </span>
          <a-tooltip slot="logtime" slot-scope="value">
            <template slot="title">{{moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')}}</template>
            <span>{{moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')}}</span>
          </a-tooltip>
          <span slot="operation" slot-scope="value, record">
            <a-button v-if="userInfo.useraccount==='admin'" type="link" size="small" @click="deleteConfirm(value, record)">删除</a-button>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
  </page-container>
</template>
<script>
import { getLogListByCondition, deleteLog } from 'A/xtpz.js'
import { getItemFromArrayByKey } from 'U'
import store from '@/store'
import moment from 'moment'
import {mapState} from "vuex";
import deptselect from "@/mixins/deptselect";
import {exportLogExcel} from "A/xtpz";
export default {
  name: 'logManagement',
  mixins:[deptselect],
  data() {
    return {
      showAdvanced: false,
      moment,
      logTypes: ['增加', '删除', '修改', '用户登录'],
      breadcrumb: [
        {
          name: '系统配置',
          path: ''
        },
        {
          name: '日志管理',
          path: ''
        },
      ],
      userdepidCascaderSelected: [],
      queryParams: {
        monitorpointnum: '',
        userdepsid:'',
        useraccount: '', //操作账号
        logtype: 3,
        logdate: [],
        // stattime: '',
        // endtime: '',
        logcontent: '',
        username:'',
        rolename:'',
      },
      tableColumns: [
        {
          title: '账号',
          dataIndex: 'useraccount',
          key: 'useraccount',
          ellipsis: true,
        },
        {
          title: '公司/项目',
          dataIndex: 'userdepname',
          key: 'userdepname',
          ellipsis: true,
        },
        {
          title: '角色',
          dataIndex: 'rolename',
          key: 'rolename',
          ellipsis: true,
        },
        {
          title: '姓名',
          dataIndex: 'username',
          key: 'username',
          ellipsis: true,
        },
        {
          title: '日志类型',
          dataIndex: 'logtype',
          key: 'logtype',
          ellipsis: true,
          scopedSlots: { customRender: 'logtype' },
        },
        {
          title: '时间',
          dataIndex: 'logtime',
          key: 'logtime',
          ellipsis: true,
          scopedSlots: { customRender: 'logtime' },
        },
        {
          title: '日志内容',
          dataIndex: 'logcontent',
          key: 'logcontent',
          ellipsis: true
        },
        {
          title: '操作',
          key: 'operation',
          width: 80,
          align: 'center',
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      currentPage: 1,
      pagination: {
        total: 0,
        pageSize: 10,
      },
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    starttime() {
      if(this.queryParams.logdate && this.queryParams.logdate[0]) {
        return moment(this.queryParams.logdate[0]).format('YYYYMMDD');
      }else {
        return ''
      }
    },
    endtime() {
      if(this.queryParams.logdate && this.queryParams.logdate[1]) {
        return moment(this.queryParams.logdate[1]).format('YYYYMMDD');
      }else {
        return ''
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    let toTab = getItemFromArrayByKey(store.state.main.tabs, 'id', to.name);
    if(!toTab) {
      store.commit('delKeepAlive', to.name)
    }
    next()
  },
  watch:{
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
  },
  created() {
    let time = new Date();
    let monthNum = moment(time).format("YYYY-MM").slice(5);
    this.queryParams.logdate[0] = moment(time).month(monthNum - 1).date(1).startOf("month").format("YYYYMMDD");
    this.queryParams.logdate[1] = moment(time).month(monthNum - 1).date(1).endOf("month").format("YYYYMMDD");
    this.init();
    this.initDeptOptionsAll();
  },
  methods: {
    init() {
      this.getTableData();
    },
    resetQueryParams() {
      this.userdepidCascaderSelected = [];
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
      this.queryParams.logdate=[]
      this.queryParams.logtype=""
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        useraccount: this.queryParams.useraccount,
        userdepsid: this.queryParams.userdepsid,
        logtype: this.queryParams.logtype,
        starttime: this.starttime,
        endtime: this.endtime,
        username:this.queryParams.username,
        logcontent: this.queryParams.logcontent,
        rolename:this.queryParams.rolename,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize,
      }
      getLogListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    pageChange(page, size) {
      this.getTableData();
    },
    onShowSizeChange(page, size) {
      this.pagination.pageSize = size;
      this.getTableData(true);
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.logid);
      }).catch(()=>{
      });
    },
    delete(logid) {
      if(logid) {
        let params = {
          logid
        };
        deleteLog(params).then(res => {
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    exportModal(){
      this.$confirm('你确认要按照所选筛选条件导出文件吗？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.exportLog()
      }).catch(()=>{
      });
    },
    exportLog(){
      let params = {
        useraccount: this.queryParams.useraccount,
        userdepsid: this.queryParams.userdepsid,
        logtype: this.queryParams.logtype,
        starttime: this.starttime,
        endtime: this.endtime,
        username:this.queryParams.username,
        logcontent: this.queryParams.logcontent,
        rolename:this.queryParams.rolename,
      }
      this.showLoading();
      exportLogExcel(params).then((res)=> {
        this.hideLoading();
        this.$message.success("导出成功")
      }).catch(()=>{
        this.hideLoading();
      })
    },
  }
}
</script>
